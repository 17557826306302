<template>
  <div class="h-bg-gray h-col-desktop-10 h-col-mobaile-12">
    <div id="headerBox" class="h-padding-left-l h-bg-w">
      <div class="h-row">
        <div class="h-col-desktop-3 h-col-mobile-12">
          <label class="h-label">Report period: </label>
          <input type="month" v-model="reportDateStart" class="h-input" />
        </div>
        <div
          class="h-col-desktop-3 h-col-mobile-12 h-margin-left-l h-mobile-margin-top-l h-mobile-margin-left-0"
        >
          <label class="h-label">Hospital:</label>
          <InputField
            id="hospitalSelect"
            type="select"
            v-model="hospitalId"
            :choices="hospitalChoices"
            :error="errors.hospital_id"
            @input="() => (errors.hospital_id = null)"
          />
        </div>
        <div
          class="h-col h-margin-bottom-s h-col-mobile-12 h-margin-left-l h-mobile-margin-top-l h-mobile-margin-left-0 h-checkbox-flex"
        >
          <InputField
            id="patientReportForm::show-only-eligible"
            type="checkbox"
            checkboxSwitchType="success"
            v-model="showOnlyEligible"
            label="Show Eligible Patients"
            data-title="Only show patients who meet reimbursement criteria"
            class="h-after-tooltip"
          />
        </div>
        <div
          class="h-2btn-flex h-col h-text-right h-mobile-margin-top-l h-mobile-margin-left-0 h-mobile-text-center"
        >
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/Refresh report.svg"
              class="h-report-icon h-padding-left-s"
              @click="recalculateReport"
            />
            <label class="h-margin-top-l"> Recalculate report </label>
          </div>
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/Down report.svg"
              class="h-report-icon h-padding-left-s"
              @click="downloadReport"
              :class="{
                'h-disabled': !hospitalId,
              }"
            />
            <label class="h-margin-top-l"> Download PDF </label>
          </div>
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/RepushReport.svg"
              class="h-report-icon h-padding-left-s"
              :class="{
                'h-disabled': !hospitalId,
              }"
              @click="publishReport"
            />
            <label class="h-margin-top-l"> Publish report </label>
          </div>
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/RepushReport.svg"
              class="h-report-icon h-padding-left-s"
              :class="{
                'h-disabled': !hospitalId,
              }"
              @click="scheduleSftpReportPush"
            />
            <label class="h-margin-top-l"> Schedule SFTP report push </label>
          </div>
        </div>
      </div>
      <TaskStatus
        reportCode="billing"
        :reportDateStart="this.reportDateStart"
        :hospitalId="this.hospitalId"
      />
      <div></div>
    </div>

    <StartTaskDialog
      ref="startTaskDialog"
      reportCode="billing"
      :month="getMonth"
      :monthStart="this.reportDateStart"
      :hospitalId="this.hospitalId"
      v-on:startProcessing="startProcessing"
      v-on:endProcessing="endProcessing"
    />

    <SftpPushDialog
      ref="sftpPushDialog"
      @allReportsPushStatusChanged="allReportsPushStatusChanged"
    />
  </div>
</template>

<style lang="stylus" scoped>
.input-row {
  margin-top: 1em;
}
</style>

<script>
import moment from "moment-timezone";
import InputField from "@/components/InputField";
import StartTaskDialog from "../StartTaskDialog";
import TaskStatus from "../TaskStatus";
import SftpPushDialog from "../hospital_report/SftpPushDialog";
import eventBus from "../../../../../event-bus";

export default {
  data() {
    return {
      reportDateStart: moment().startOf("month").format("YYYY-MM"),
      reportDateEnd: moment().endOf("month").format("YYYY-MM"),
      skipEmptyReports: true,
      hospitalId: null,
      hospitals: [],
      errors: {},
      reportsInfo: {},
      showOnlyEligible: true,
      clientIdsToIncludeInZipDownload: [],
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.sort((a, b) => a[1].localeCompare(b[1]));
      choices.unshift([null, "--- select hospital ---"]);
      return choices;
    },

    downloadEnabled() {
      return !!this.hospitalId;
    },
    getMonth() {
      if (this.reportDateStart) {
        return moment(this.reportDateStart).month() + 1;
      }
      return moment().month() + 1;
    },
  },

  watch: {
    reportDateStart() {
      this.emitChange();
    },

    hospitalId() {
      this.emitChange();
    },

    showOnlyEligible() {
      this.emitChange();
    },
  },

  methods: {
    emitChange() {
      this.reportDateEnd = moment(this.reportDateStart)
        .endOf("month")
        .format("YYYY-MM");
      this.$emit("dateRange", {
        hospitalId: this.hospitalId,
        dateStart: moment(this.reportDateStart)
          .startOf("month")
          .format("YYYY-MM-DD"),
        dateEnd: moment(this.reportDateEnd).endOf("month").format("YYYY-MM-DD"),
        showOnlyEligible: this.showOnlyEligible,
      });
    },

    startProcessing() {
      this.$emit("startProcessing");
    },

    endProcessing() {
      this.$emit("endProcessing");
    },

    downloadReport() {
      this.$emit("downloadReport");
    },

    recalculateReport() {
      this.$refs.startTaskDialog.show();
    },

    async publishReport() {
      try {
        await this.$api.publishBillingReport({
          hospital_id: this.hospitalId,
          start_date: moment(this.reportDateStart)
            .startOf("month")
            .format("YYYY-MM-DD"),
          end_date: moment(this.reportDateEnd)
            .endOf("month")
            .format("YYYY-MM-DD"),
        });
        $.notify("Billing report is published.", {
          position: "top center",
          className: "success",
        });
      } catch {
        $.notify("Report publish is failed", {
          position: "top center",
          className: "error",
        });
      }
    },

    async scheduleSftpReportPush() {
      if (!this.hospitalId) return;
      this.$refs.sftpPushDialog.show({
        report: {
          hospitalId: this.hospitalId,
          date_start: moment(this.reportDateStart)
            .startOf("month")
            .format("YYYY-MM-DD"),
          date_end: moment(this.reportDateEnd)
            .endOf("month")
            .format("YYYY-MM-DD"),
          reportCode: "billing",
        },
      });
    },

    allReportsPushStatusChanged() {
      this.$emit("allReportsPushStatusChanged");
    },
  },

  async mounted() {
    this.hospitals = await this.$api.getHospitalsList();

    this.emitChange();

    eventBus.$on(
      "clientIdsSelectedChanged",
      (event) => {
        this.clientIdsToIncludeInZipDownload = event.ids;
      },
      this
    );
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
  },

  components: {
    InputField,
    StartTaskDialog,
    TaskStatus,
    SftpPushDialog,
  },
};
</script>
