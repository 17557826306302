import * as moment from 'moment';
import AppointmentTypesEnum from '../enums/index';

const createTechCallAppointment = async (that) => {
  const appointment = {};

  if (that.client) {
    appointment.client = that.client;
  }

  const payload = JSON.parse(JSON.stringify(appointment));

  payload.duration_minutes = 0;
  payload.duration_seconds = 0;

  payload.planned_duration = 10;

  payload.zoom_utc_offset = that.$dateUtils.utcOffsetMinutes;

  const currentDate = moment().toDate();
  payload.start_date = `${currentDate.getFullYear()
    }-${currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
  payload.start_time = `${currentDate.getHours()}:${currentDate.getMinutes()}`;

  if (payload.start_time && payload.start_date) {
    const startDateTimeUtc = that.$dateUtils.toUtcDatetime(
      `${payload.start_date} ${payload.start_time}`,
    );
    const timeParts = startDateTimeUtc.split(' ');
    payload.start_date = timeParts[0];
    payload.start_time = timeParts[1];
  }

  const d = new Date();
  payload.utcOffset = d.getTimezoneOffset();

  payload.appointment_type = 'tech_call';

  const response = await that.$api.createMeeting(payload);

  if (response.status === 'error') {
    this.loading = false;
    this.errors = response.errors;

    $.notify('Failed to created the tech call appointment.', {
      position: 'top center',
      className: 'error',
    });
  }

  return response;
};

// eslint-disable-next-line import/prefer-default-export
export { createTechCallAppointment };
