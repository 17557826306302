<template>
  <div>
    <div
      class="h-padding-left-l h-padding-right-l h-padding-top-l
    h-space-between h-align-items-center"
    >
      <h5 class="h-h3 h-primary_shade_1">Assessments</h5>
      <div>
        <button type="button" class="h-btn h-btn-accent" @click="edit">
          <div class="h-align-items-center">
            <i class=" h-secondary_shade_5 healenticons-edit h-padding-right-s"></i>Edit
          </div>
        </button>
      </div>
    </div>
    <div v-if="!loading" class="h1-table">
      <table class="table dataTable">
        <thead class="thead-light">
          <tr>
            <th class="w-1 text-nowrap text-left">Start date</th>
            <th class="w-1 text-nowrap text-left">Is after</th>
            <th class="w-1 text-nowrap text-right">Days before/after</th>
            <th>Name</th>
            <th class="w-1 text-nowrap text-right">Repeat every X days</th>
            <th class="w-1 text-nowrap text-left">Source</th>
          </tr>
        </thead>

        <tbody v-if="items.length !== 0">
          <tr v-for="item of items" :key="item.id">
            <td class="text-left">
              {{ item.start_date_type_display }}
            </td>
            <td class="text-left">
              {{ item.post_op ? 'Yes' : 'No' }}
            </td>
            <td class="text-right">
              {{ item.shift_in_days }}
            </td>
            <td>
              {{ item.assesment.title }}
            </td>
            <td class="text-right">
              {{ item.repeat_every_x_days }}
            </td>
            <td class="text-left">
              {{ item.care_protocol_name }}
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td class="text-center" colspan="100">No entries</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Loader v-else />

    <EditClientCPAssessment ref="editDialog" :client="client" @updated="getClientCPAssessments" />
  </div>
</template>

<style lang="stylus" scoped>
.edit-button {
  min-width: 10em;
}
</style>

<script>
import Loader from '@/components/Loader';
import EditClientCPAssessment from './components/EditClientCPAssessment';

export default {
  name: 'ClientCPAssessment',

  components: {
    Loader,
    EditClientCPAssessment,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    };
  },

  methods: {
    async getClientCPAssessments() {
      try {
        const resp = await this.$api.getClientCPAssessments(this.client.id, {
          start: 0,
          length: 100,
        });
        this.items = resp.data;
      } catch (e) {
        this.items = [];
      }
    },

    edit() {
      if (!this.client.care_protocol) {
        $.notify("The patient hasn't a care protocol yet. Add care protocol first.", {
          position: 'top center',
          className: 'error',
        });
        return;
      }
      this.$refs.editDialog.show(this.items);
    },
  },

  async mounted() {
    await this.getClientCPAssessments();
  },
};
</script>
