<template>
  <div class="h-padding-top-l h-padding-left-l h-padding-right-l">
    <div
      class="h-AllPatients-filter h-margin-bottom-s"
      @mousedown="startDrag"
      @mouseleave="stopDrag"
      @mouseup="stopDrag"
      @mousemove="doDrag"
      :class="allPatientsFilterClass">
      <div class="h-AllPatients-filter-card new-filter 1">
        <div class="h-filter-item-sub h-horizontal">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'incompleteNotes' }"
            @click="toggleActive('incompleteNotes', $event)">
            <h4 class="h-filter-text" data-tooltip="Patient notes are not marked as completed">
              <span
                class="h-dangerous"
                data-tooltip="Patient notes are not marked as completed">
                {{ filtered.incompleteNotes || 0 }}
              </span>
              Patients With Incomplete Notes
            </h4>
          </div>
          <!-- <div
            class="FilterItem"
            :class="{ active: activeFilter === 'unReadMessages' }"
            @click="toggleActive('unReadMessages', $event)"
          >
            <h4
              class="h-filter-text"
              data-tooltip="Unread Messages"
            >
              <span
                class="h-dangerous"
                data-tooltip="Unread Messages"
              >
                {{ filtered.unReadMessages || 0 }}
              </span>
              Unread Messages
            </h4>
          </div> -->
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'scheduledCalls' }"
            @click="toggleActive('scheduledCalls', $event)">
            <h4
              class="h-filter-text"
              data-tooltip="Calls Today">
              <span
                class="h-dangerous"
                data-tooltip="Calls Today">
                {{ filtered.scheduledCalls || 0 }}
              </span>
              Calls Today
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'bhiAlerts' }"
            @click="toggleActive('bhiAlerts', $event)">
            <h4
              class="h-filter-text"
              data-tooltip="BHI Alerts">
              <span
                class="h-dangerous"
                data-tooltip="BHI Alerts">
                {{ filtered.bhiAlerts || 0 }}
              </span>
              Patients With BHI Alerts
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'otherAlert' }"
            @click="toggleActive('otherAlert', $event)">
            <h4
              class="h-filter-text"
              data-tooltip="Patients With RPM/RTM Alerts">
              <span
                class="h-dangerous"
                data-tooltip="Patients With RPM/RTM Alerts">
                {{ alertsCount || 0 }}
              </span>
              Patients With RPM/RTM Alerts
            </h4>
          </div>
        </div>
      </div>
      <div class="h-AllPatients-filter-card flex-1">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'time_eligible' }"
          @click="toggleActive('time_eligible', $event)">
          <h2 class="h-filter-number" data-tooltip="all patients who are eligible end of the month if they meet the eligibility criteria ">
            {{ filtered.time_eligible }}
          </h2>
          <h4 class="h-filter-text" data-tooltip="all patients who are eligible end of the month if they meet the eligibility criteria ">
            Still Eligible This Month (time)
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'zeroMin' }"
            @click="toggleActive('zeroMin', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients with no time spent this month">
              {{ filtered.zeroMin || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with no time spent this month">
              0min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'between1and10' }"
            @click="toggleActive('between1and10', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients with 1-10min spent this month">
              {{ filtered.between1and10 || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with 1-10min spent this month">
              1-10min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'between11and19' }"
            @click="toggleActive('between11and19', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients with 11-19min spent this month">
              {{ filtered.between11and19 || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with 11-19min spent this month">
              11-19min
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'moreThan20' }"
            @click="toggleActive('moreThan20', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients with more than 20min spent this month">
              {{ filtered.moreThan20 || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with more than 20min spent this month">
              20min +
            </h4>
          </div>
        </div>
      </div>
      <div class="h-AllPatients-filter-card flex-1">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'data_eligible' }"
          @click="toggleActive('data_eligible', $event)">
          <h2 class="h-filter-number" data-tooltip="Eligible This Month (data)">
            {{ filtered.data_eligible }}
          </h2>
          <h4 class="h-filter-text" data-tooltip="Eligible This Month (data)">
            Still Eligible This Month (data)
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'data_gap_0' }"
            @click="toggleActive('data_gap_0', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients with no data this month">
              {{ filtered.data_gap_0 || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with no data this month">
              0 days
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'data_gap_1_10' }"
            @click="toggleActive('data_gap_1_10', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients between 1-10 readings this month">
              {{ filtered.data_gap_1_10 || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients between 1-10 readings this month">
              1-10 days
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'data_gap_11_15' }"
            @click="toggleActive('data_gap_11_15', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients between 10-15 readings this month">
              {{ filtered.data_gap_11_15 || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients between 10-15 readings this month">
              11-15 days
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'data_16' }"
            @click="toggleActive('data_16', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients with more than 16 readings this month">
              {{ filtered.data_16 || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients with more than 16 readings this month">
              16+ days
            </h4>
          </div>
        </div>
      </div>
      <div class="h-AllPatients-filter-card flex-1">
        <div
          class="FilterItem h-filter-item-head"
          :class="{ active: activeFilter === 'allPatients' }"
          @click="toggleActive('allPatients', $event)">
          <h2 class="h-filter-number" data-tooltip="All patients count">
            {{ filtered.allPatients }}
          </h2>
          <h4 class="h-filter-text" data-tooltip="All patients count">
            Total Patients
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'newPatientsThisMonth' }"
            @click="toggleActive('newPatientsThisMonth', $event)">
            <h3 class="h-filter-number" data-tooltip="New patients this month">
              {{ filtered.newPatientsThisMonth || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="New patients this month">
              New
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'activePatients' }"
            @click="toggleActive('activePatients', $event)">
            <h3 class="h-filter-number" data-tooltip="Active patients">
              {{ filtered.activePatients || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Active patients">Active</h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'inActivePatients' }"
            @click="toggleActive('inActivePatients', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients who are inactive">
              {{ filtered.inActivePatients || 0 }}
            </h3>
            <h4 class="h-filter-text" data-tooltip="Patients who are inactive">
              Inactive
            </h4>
          </div>
          <div
            class="FilterItem"
            :class="{ active: activeFilter === 'pendingPatients' }"
            @click="toggleActive('pendingPatients', $event)">
            <h3
              class="h-filter-number"
              data-tooltip="Patients who are awaiting activation">
              {{ filtered.pendingPatients || 0 }}
            </h3>
            <h4
              class="h-filter-text"
              data-tooltip="Patients who are awaiting activation">
              Pending
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="h-bg-w h-padding-top-l h-margin-bottom-l">
      <div class="h-card-body" :class="cardBodyClass">
        <div class="h-d-flex h-space-between h-margin-right-l h-margin-left-l">
          <div>
            <div class="h-d-flex h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1 h-align-items-center">
                Patients
                <span class="h-h5 h-primary_shade_1 h-padding-left-xs">({{ tooltipContent }})</span>
              </h3>
              <InputField
                id="fullscreen"
                type="toggle"
                label="Full view"
                class="h-margin-left-s h-toggle-no-mb"
                @change="toggleFullscreen" />
            </div>
            <div
              class="filter-summary h-d-flex h-padding-top-xs h-padding-right-s">
              <template
                v-if="
                  selectedHospital > 0 ||
                  selectedNurse > 0 ||
                  selectedProvider > 0 ||
                  selectedCp > 0 ||
                  selectedProgram > 0
                ">
                <h5 class="h-h5 h-secondary_shade_1">
                  Filtered by:
                  <template v-if="selectedHospital > 0">
                    <span>{{ getHospitalName(selectedHospital) }}</span>
                  </template>
                  <template v-if="selectedNurse > 0">
                    <span v-if="selectedHospital > 0">, </span>
                    <span>{{ getNurseName(selectedNurse) }}</span>
                  </template>
                  <template v-if="selectedProvider > 0">
                    <span v-if="selectedHospital > 0 || selectedNurse > 0">,
                    </span>
                    <span>{{ getProviderName(selectedProvider) }}</span>
                  </template>
                  <template v-if="selectedCp > 0">
                    <span
                      v-if="
                        selectedHospital > 0 ||
                        selectedNurse > 0 ||
                        selectedProvider > 0
                      ">,
                    </span>
                    <span>{{ getCareProtocolName(selectedCp) }}</span>
                  </template>
                  <template v-if="selectedProgram > 0">
                    <span
                      v-if="
                        selectedHospital > 0 ||
                        selectedNurse > 0 ||
                        selectedProvider > 0 ||
                        selectedCp > 0
                      ">,
                    </span>
                    <span>{{ getProgramName(selectedProgram) }}</span>
                  </template>
                  <template v-if="
                    selectedHospital === 0 &&
                    selectedNurse === 0 &&
                    selectedProvider === 0 &&
                    selectedCp === 0 &&
                    selectedProgram === 0
                  ">
                    <span>None</span>
                  </template>
                  <i v-if="
                    selectedHospital > 0 ||
                    selectedNurse > 0 ||
                    selectedProvider > 0 ||
                    selectedCp > 0 ||
                    selectedProgram > 0
                  " class="h-cursor-pointer h-dangerous healenticons-close-circle h-padding-left-s" @click="resetFilters"></i>
                </h5>
              </template>
            </div>
          </div>
          <div class="h-d-flex h-align-items-center align-items-center w-300">
            <div class="search-container">
              <input type="search" placeholder="Type, then click search" class="h-input search-input" v-model="textSearch" @blur="handleInput" @keyup.enter="handleInput" />
              <button class="search-icon" @click="handleInput()">
                <i class="healenticons-search" data-tooltip="Click to search"></i>
              </button>
            </div>
            <button style="width: max-content" class="h-btn h-btn-accent h-margin-left-m h-align-items-center" @click="addPatient()">
              <i style="line-height: 1; font-size: 10rem" class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"></i>Add new patient
            </button>
            <div class="h-relative h-line-height-1 dropdown h-add-dropdown">
              <a class="dropdown-toggle arrow-none" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false" style="background: white">
                <i class="h-h3 h-secondary_shade_1 healenticons-download h-margin-left-m">
                </i>
              </a>
              <div ref="collectionDropdown" class="dropdown-menu assessment-dropdown"></div>
            </div>

            <i class="h-h3 h-secondary_shade_1 healenticons-gear-fill h-cursor-pointer h-margin-left-m" @click="addedAction"></i>
          </div>
        </div>

        <div class="h-row">
          <div class="col-lg-12 mt-2">
            <div class="table-responsive h1-table">
              <table id="all-patients" class="table" ref="table">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%" class="text-left noTooltip">
                      <span class="table-title-bold">Action</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop h-text-left">
                      <span class="table-title-bold">ID</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Alerts</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Auto nudging</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Patient name</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField class="hdr" name="hospitalSelect" type="select" :choices="hospitalChoices" @change="event => onChangeInput(event, 'hospital_id')" v-model="selectedHospital" />
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Date of birth</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Mobile No</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">MRN number</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Insurance</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <span class="table-title-bold">Care protocol</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Location</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Last in-app activity</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Last reading on</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Data this month (days)</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField class="hdr" type="select" :choices="[
                        { value: 0, title: 'Monitoring program' },
                        { value: 1, title: 'CCM' },
                        { value: 2, title: 'PCM' },
                        { value: 3, title: 'RPM' },
                        { value: 4, title: 'RTM' },
                        { value: 5, title: 'Wellness' }
                      ]" @change="event => onChangeInput(event, 'monitoring_program')" v-model="selectedProgram" />
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Time Eligible</span>
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField class="hdr" type="select" :choices="nurseOptions" @change="event => onChangeInput(event, 'assigned_nurse_id')" v-model="selectedNurse" />
                    </th>
                    <th style="width: 5%" class="noTooltip">
                      <InputField class="hdr" type="select" :choices="surgeonOptions" @change="event => onChangeInput(event, 'surgeon_id')" v-model="selectedProvider" />
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Plan Start date</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Plan End date</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Last appoint.</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Next appoint.</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Status Reason</span>
                    </th>
                    <th style="width: 5%" class="AllPatients-tooltop">
                      <span class="table-title-bold">Comments</span>
                    </th>
                  </tr>
                  <!--end tr-->
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--end card-body-->
    </div>
    <!--end card-->

    <SelectorDialogInDrawer ref="toggleColumnsDialog" class="h-hide-id" :allOptions="allTableColumns" :selectedOptions="visibleTableColumns" :getOptionKey="(option) => {
      return option.name;
    }
      " :getOptionText="(option) => {
        return option.text;
      }
        " windowTitle="Toggle table columns" listTitle="Table table columns to show" @toggleOption="toggleColumnVisible" />
    <EditProfileInfoTabDrawer ref="EditProfileInfoTabDrawer" :client="client" :key="client.id" />
    <EditStatusDrawer ref="EditStatusDrawer" :client="client" :key="client.id" />
    <ResendActivationCodeDialog ref="ResendActivationCodeDialog" />
    <SimpleMessage ref="simpleMessage" :breakAll="true" />
    <PatientProfileInDrawer ref="patientProfileDrawer" v-if="showPatientProfileInDrawer" @profileClosed="showPatientProfileInDrawer = false" :key="client.id" />
  </div>
</template>

<style scoped lang="stylus"></style>

<script>
import moment from 'moment-timezone';
import lottie from 'lottie-web';
import settings from '@/settings.js';
import EditProfileInfoTabDrawer from '../edit_patient/EditProfileInfoTabDrawer';
import EditStatusDrawer from '../edit_patient/EditStatusDrawer';
import SelectorDialogInDrawer from '@/components/dialogs/SelectorDialogInDrawer';
import ResendActivationCodeDialog from './components/ResendActivationCodeDialog';
import PatientProfileInDrawer from './components/PatientProfileInDrawer';
import SimpleMessage from '@/components/dialogs/SimpleMessage';
import InputField from '@/components/InputField';
import eventBus from '../../../event-bus';
import eventKeeper from '../../../eventKeeper';
import animationData from '../../../../public/assets/images/Bot-Waiting-v2.json';

const TABLE_COLUMNS_KEY = 'AllPatients_columnKeys';

export default {
  data() {
    return {
      settings,
      client: {},
      patientsData: [],
      dataTable: null,
      allTableColumns: [],
      visibleTableColumns: [],
      hospitals: [],
      careProtocolChoices: [],
      surgeons: [],
      nurses: [],

      intervalId: null,

      // row filters
      selectedHospital: 0,
      selectedNurse: 0,
      selectedProvider: 0,
      selectedCp: 0,
      selectedProgram: 0,

      filtered: {
        patientContacts: 0,
        scheduledCalls: 0,
        unReadMessages: 0,

        nudgeCount: 0,
        nudgeSms: 0,
        nudgeCall: 0,
        nudgeFailed: 0,
        allAlert: 0,
        rpmAlert: 0,
        rtmAlert: 0,
        otherAlert: 0,

        missing1d: 0,
        missing3d: 0,
        missing7d: 0,
        techIssue: 0,
        laziness: 0,

        patientsReminded: 0,
        totalReminders: 0,
        resolvedReminders: 0,
        ignoredReminders: 0,
        openedReminders: 0,

        zeroMin: 0,
        between1and10: 0,
        between11and19: 0,
        moreThan20: 0,

        countOfDaysDataProvided: 0,
        incompleteNotes: 0,
        noChartChecks15d: 0,
        noCalls21d: 0,

        activePatients: 0,
        inActivePatients: 0,
        pendingPatients: 0,
        misconfiguredPatients: 0,
        newPatientsThisMonth: 0,

        data_eligible: 0,
        time_eligible: 0,
      },

      activeFilter: 'newPatientsThisMonth',
      columnFilters: {
        hospital_id: 0,
        assigned_nurse_id: 0,
        surgeon_id: 0,
        care_protocol_id: 0,
        monitoring_program: '',
      },
      draw: 0,
      textSearch: '',
      tooltipContent: 'Active patients',
      isFullscreen: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      showPatientProfileInDrawer: !!this.$route.query.patientID,
      debouncedStateSave: this.debounce(this.customStateSave, 500),
    };
  },

  computed: {
    cardBodyClass() {
      return this.isFullscreen ? 'fullscreen' : '';
    },
    allPatientsFilterClass() {
      return this.isFullscreen ? 'h-d-none' : '';
    },
    isAdmin() {
      return settings.hasRole('system_administrator');
    },
    alertsCount() {
      return (
        (this.filtered.rpmAlert || 0) +
        (this.filtered.rtmAlert || 0) +
        (this.filtered.otherAlert || 0)
      );
    },
    missingDataCount() {
      return (
        (this.filtered.missing1d || 0) +
        (this.filtered.missing3d || 0) +
        (this.filtered.missing7d || 0)
      );
    },
    time_eligible() {
      return (
        (this.filtered.zeroMin || 0) +
        (this.filtered.between1and10 || 0) +
        (this.filtered.between11and19 || 0) +
        (this.filtered.moreThan20 || 0)
      );
    },
    data_eligible() {
      return (
        (this.filtered.data_gap_0 || 0) +
        (this.filtered.data_gap_1_10 || 0) +
        (this.filtered.data_gap_11_15 || 0) +
        (this.filtered.data_16 || 0)
      );
    },
    ineligiblePatients() {
      return (
        (this.filtered.countOfDaysDataProvided || 0) +
        (this.filtered.incompleteNotes || 0) +
        (this.filtered.noChartChecks15d || 0) +
        (this.filtered.noCalls21d || 0)
      );
    },
    allPatients() {
      return (
        (this.filtered.activePatients || 0) +
        (this.filtered.inActivePatients || 0) +
        (this.filtered.pendingPatients || 0)
      );
    },

    resolved() {
      return this.filtered.totalReminders > 0
        ? Math.round(
          (this.filtered.resolvedReminders * 100) /
          this.filtered.totalReminders,
        )
        : 0;
    },
    ignored() {
      return this.filtered.totalReminders > 0
        ? Math.round(
          (this.filtered.ignoredReminders * 100) /
          this.filtered.totalReminders,
        )
        : 0;
    },
    opened() {
      return this.filtered.totalReminders > 0
        ? Math.round(
          (this.filtered.openedReminders * 100) / this.filtered.totalReminders,
        )
        : 0;
    },

    hospitalChoices() {
      const choices = this.hospitals.map(hospital => [
        hospital.id,
        hospital.name,
      ]);
      choices.unshift([0, 'Facility']);
      return choices;
    },

    surgeonOptions() {
      const choices = this.surgeons
        .map((surgeon) => {
          if (
            this.hospitalId &&
            this.hospitalId !== 'true' &&
            surgeon.hospital.id !== parseInt(this.hospitalId, 10)
          ) {
            return null;
          }
          return [surgeon.id, surgeon.name];
        })
        .filter(entry => entry !== null);
      choices.unshift([0, 'Physician']);
      return choices;
    },
    nurseOptions() {
      const choices = this.nurses
        .map((nurse) => {
          if (this.hospitalId && this.hospitalId !== 'true') {
            const foundHospital = nurse.hospitals.find(
              hospital => hospital.id === parseInt(this.hospitalId, 10),
            );
            if (!foundHospital) {
              return null;
            }
          }
          return [nurse.id, nurse.name];
        })
        .filter(entry => entry !== null);
      choices.unshift([0, 'Care provider']);
      return choices;
    },
    CareProtocolChoices() {
      const choices = settings.careProtocols
        .map(protocol => [
          protocol.id,
          protocol.is_global
            ? protocol.name
            : `${protocol.name} [${protocol.hospital.name}]`,
        ])
        .filter(entry => entry !== null);
      choices.unshift([0, 'Care protocols']);
      return choices;
    },
  },

  methods: {
    debounce(func, wait) {
      let timeout;
      return function debounced(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    async getHospitalList() {
      try {
        this.hospitals = await this.$api.getHospitalsList();
      } catch (e) {
        this.hospitals = [];
      }
    },

    async searchSurgeon() {
      try {
        this.surgeons = await this.$api.searchSeargeon();
      } catch (e) {
        this.surgeons = [];
      }
    },

    async searchNurse() {
      try {
        this.nurses = await this.$api.searchNurse();
      } catch (e) {
        this.nurses = [];
      }
    },

    async getAllPatientsStats() {
      // try {
      //   this.filtered = await this.$api.getAllPatientsStats();
      // } catch (e) {
      //   this.filtered = {};
      // }
    },
    customStateSave(customSettings, data) {
      data.selectedHospital = this.selectedHospital;
      data.selectedNurse = this.selectedNurse;
      data.selectedProvider = this.selectedProvider;
      data.selectedCp = this.selectedCp;
      data.selectedProgram = this.selectedProgram;
      data.columnFilters = this.columnFilters;
      localStorage.setItem('dtState', JSON.stringify(data));
    },

    customStateLoad(customSettings) {
      const data = JSON.parse(localStorage.getItem('dtState')) || {};
      this.selectedHospital = data.selectedHospital || 0;
      this.selectedNurse = data.selectedNurse || 0;
      this.selectedProvider = data.selectedProvider || 0;
      this.selectedCp = data.selectedCp || 0;
      this.selectedProgram = data.selectedProgram || 0;
      this.columnFilters = data.columnFilters || this.columnFilters;
      return data;
    },
    initializeTable() {
      const that = this;
      const allColumns = [
        {
          data: 'action',
          name: 'action',
          searchable: false,
          render: (data, type, row, meta) => {
            const id = row.id;
            const isConfirmedClass = row.is_confirmed ? 'd-none' : '';
            return `
            <div class="text-left action-td">
              <a href="javascript:void(0);" class="EditProfile" data-id="${id}">
                <i class='healenticons-form-input-icon-41 text-info font-20' data-tooltip='Edit Profile'></i>
              </a>
              <a href="javascript:void(0);" class="editStatus" data-id="${id}">
                 <i class='healenticons-form-input-icon-34 text-info font-20' data-tooltip='Edit Status'></i>
               </a>
              <a href="javascript:void(0);"
                class=" resend-invite-letter ${isConfirmedClass}"
                data-id="${id}"
              >
                <i class="healenticons-sms text-info font-16"
                title="Resend Welcome SMS"></i>
              </a>
              <a href="javascript:void(0);" class="mr-2 onepage-report-btn" data-id="${id}">
                 <i class='healenticons-reports text-info font-20' data-tooltip='One Page Report'></i>
               </a>
            </div>
          `;
          },
        },
        {
          data: 'id',
          name: 'id',
          searchable: true,
          render: data => (data
            ? ` <div class="idToClipboard cursor-pointer" data-tooltip="Copy patient ID to clipboard">#${that.$strUtils.escapeHtml(
              data,
            )}</div>`
            : null),
          className: 'text-nowrap',
        },
        {
          data: 'alerts',
          name: 'alerts',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            let showAlerts = '<div class="h-alert-icons">';

            // Check for existing alerts
            let hasAlerts = false;
            if (data) {
              const clientAlerts = data
                .replace(/\s+/g, '')
                .split(',')
                .filter(i => i);

              if (clientAlerts.length > 0) {
                hasAlerts = true;

                if (clientAlerts.includes('low_data')) {
                  showAlerts += `<i class="h-dangerous healenticons-low-data"
                            data-tooltip="Patient has not enough readings this month"></i>`;
                }
                if (clientAlerts.includes('empty_note')) {
                  showAlerts += `<i class="h-high healenticons-uncompleted-notes"
                            data-tooltip="Patient has incomplete or empty notes this month"></i>`;
                }
                if (clientAlerts.includes('upcoming_appt')) {
                  showAlerts += `<i class="h-high healenticons-subtraction-29"
                            data-tooltip="Patient has an upcoming appointment in the next 7 days"></i>`;
                }
                if (clientAlerts.includes('phq9_alert')) {
                  showAlerts += `<i class="h-dangerous healenticons-out-of-range-values-1"
                            data-tooltip="Suicide risk is determined based on the ninth question of the PHQ-9 assessment"></i>`;
                }
                if (clientAlerts.includes('spo2_alert')) {
                  showAlerts += `<i class="h-dangerous healenticons-out-of-range-values"
                            data-tooltip="Out of range spo2 value"></i>`;
                }
                if (clientAlerts.includes('monthly_call')) {
                  showAlerts += `<i class="h-accent healenticons-no-issues"
                            data-tooltip="Patient has completed one or more calls for last 30 days"></i>`;
                }
              }
            }

            // Check if the plan_end_date has expired
            const planEndDate = row.plan_end_date ? moment(row.plan_end_date) : null;
            const today = moment();
            const isPlanExpired = planEndDate && planEndDate.isBefore(today, 'day');

            // If no alerts and the plan is expired, show both "No alerts" and "Plan expired" icons
            if (!hasAlerts && isPlanExpired) {
              showAlerts = `
        <div class="h-alert-icons">
          <i class="h-secondary_shade_1 healenticons-no-issues" data-tooltip="No alerts"></i>
          <i class="h-high healenticons-with-alerts" data-tooltip="Patient's plan has expired"></i>
        </div>
      `;
            }
            // If there are alerts and the plan is expired, add the "Plan expired" icon along with the other alerts
            else if (hasAlerts && isPlanExpired) {
              showAlerts += `<i class="h-high healenticons-with-alerts"
                         data-tooltip="Patient's plan has expired"></i>`;
            }

            // If no alerts and the plan is not expired, show only the "No alerts" icon
            if (!hasAlerts && !isPlanExpired) {
              showAlerts = `
        <div class="h-alert-icons">
          <i class="h-secondary_shade_1 healenticons-no-issues" data-tooltip="No alerts"></i>
        </div>
      `;
            }

            showAlerts += '</div>';
            return showAlerts;
          }
        }

        ,
        {
          data: 'auto_nudging',
          name: 'auto_nudging',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data
              ? `
              <div class="h-alert-icons">
                 <i class="h-accent healenticons-no-issues"></i>
               </div>
              `
              : `<div class="h-alert-icons">
                 <i class="h-secondary_shade_1 healenticons-no-issues"></i>
               </div>
              `;
          },
        },
        // {
        //   data: 'risk_of_dropping_out',
        //   name: 'risk_of_dropping_out',
        //   searchable: false,
        //   orderable: true,
        //   render: (data) => {
        //     if (data) {
        //       const rounded = Math.round(data * 100);
        //       return Math.min(rounded, 100);
        //     }
        //     return '-';
        //   },
        // },
        {
          data: 'name',
          name: 'name',
          orderable: true,
          searchable: true,
          render: (data, type, row, meta) => {
            const id = row.id;
            return `
            <a href="javascript:void(0);" class="OpenProfileInDrawer"  data-id="${id}">
              ${that.$strUtils.escapeHtml(data)}
            </a>
          `;
          },
          className: 'text-nowrap',
        },
        {
          data: 'hospital__name',
          name: 'hospital__name',
          searchable: true,
          orderable: true,
        },
        // {
        //   data: 'age',
        //   name: 'age',
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        //   className: 'text-nowrap',
        // },
        {
          data: 'date_of_birth',
          name: 'date_of_birth',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            if (typeof data === 'string' && parseInt(data.split('-')[0], 10) < 1000) {
              return `Invalid date ${data}`;
            }
            const date = moment(data);
            return date.format('MMM D, YYYY');
          },
        },
        {
          data: 'phone',
          name: 'phone',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        // {
        //   data: 'email',
        //   name: 'email',
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        // },
        // {
        //   data: 'bmi_calculated',
        //   name: 'bmi_calculated',
        //   searchable: false,
        //   orderable: true,
        //   render: data => data || '-',
        // },
        // {
        //   data: 'weight_lbs',
        //   name: 'weight_lbs',
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data ? `${data} lbs` : '-'),
        // },
        // {
        //   data: 'height',
        //   name: 'height',
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data ? `${data} inches` : '-'),
        // },
        {
          data: 'mrn',
          name: 'mrn',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          data: 'insurance_info',
          name: 'insurance_info',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        // {
        //   data: 'surgery_date',
        //   name: 'surgery_date',
        //   searchable: false,
        //   orderable: true,
        //   render: (data) => {
        //     if (data) {
        //       const date = moment(data);
        //       return date.format('MMM D, YYYY h:mm a');
        //     }
        //     return '-';
        //   },
        // },
        // {
        //   data: 'discharge_date',
        //   name: 'discharge_date',
        //   searchable: false,
        //   orderable: true,
        //   render: (data) => {
        //     if (data) {
        //       const date = moment(data);
        //       return date.format('MMM D, YYYY');
        //     }
        //     return '-';
        //   },
        // },
        // {
        //   data: 'A1C',
        //   name: 'A1C',
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        // },
        {
          data: 'care_protocol__name',
          name: 'care_protocol__name',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          data: 'location__name',
          name: 'location__name',
          searchable: false,
          orderable: true,
          render: data => (data ? that.$strUtils.escapeHtml(data) : '-'),
        },
        {
          data: 'user__last_login_activity',
          name: 'user__last_login_activity',
          searchable: false,
          orderable: true,
          visible: false,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY h:mm a') : '-';
          },
        },
        {
          data: 'last_updated_at',
          name: 'last_updated_at',
          searchable: false,
          orderable: true,
          visible: true,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY h:mm a') : '-';
          },
        },
        {
          data: 'mi_compliance__score',
          name: 'mi_compliance__score',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return !isNaN(parseFloat(data)) ? Math.round(parseFloat(data)).toFixed(0) : 0;
          },
        },
        {
          data: 'monitoring_program',
          name: 'monitoring_program',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          data: 'accumulated_time',
          name: 'accumulated_time',
          searchable: false,
          orderable: true,
          render: data => (data ? `${data ?? 0} mins` : '-'),
        },
        {
          data: 'assigned_nurse__name',
          name: 'assigned_nurse__name',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },
        {
          data: 'surgeon__name',
          name: 'surgeon__name',
          searchable: false,
          orderable: true,
          render: data => data || '-',
        },

        // {
        //   data: 'messages_sent_this_month',
        //   name: 'messages_sent_this_month',
        //   __adminOnly: true,
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data != null ? `${data}` : '-'),
        // },
        // {
        //   data: 'messages_received_this_month',
        //   name: 'messages_received_this_month',
        //   __adminOnly: true,
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data != null ? `${data}` : '-'),
        // },
        // {
        //   data: 'outbound_calls_this_month',
        //   name: 'outbound_calls_this_month',
        //   __adminOnly: true,
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data != null ? `${data}` : '-'),
        // },
        // {
        //   data: 'inbound_calls_this_month',
        //   name: 'inbound_calls_this_month',
        //   __adminOnly: true,
        //   searchable: false,
        //   orderable: true,
        //   render: data => (data != null ? `${data}` : '-'),
        // },
        // {
        //   data: 'created_date_time_utc',
        //   name: 'created_date_time_utc',
        //   searchable: false,
        //   orderable: true,
        //   render: (data, type, row, meta) => {
        //     return data ? moment(data).format('MMM D, YYYY') : '-';
        //   },
        // },
        {
          data: 'plan_start_date',
          name: 'plan_start_date',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY') : '-';
          },
        },
        {
          data: 'plan_end_date',
          name: 'plan_end_date',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data ? moment(data).format('MMM D, YYYY') : '-';
          },
        },
        // {
        //   data: 'meeting_count',
        //   name: 'meeting_count',
        //   searchable: false,
        //   orderable: true,
        //   render: data => data || '-',
        // },
        {
          data: 'last_meeting_datetime',
          name: 'last_meeting_datetime',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data
              ? moment(this.$dateUtils.toLocalDatetime(data)).format('MMM D, YYYY h:mm a')
              : '-';
          },
        },
        {
          data: 'next_meeting_datetime',
          name: 'next_meeting_datetime',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return data
              ? moment(this.$dateUtils.toLocalDatetime(data)).format('MMM D, YYYY h:mm a')
              : '-';
          },
        },
        // {
        //   data: 'hif_score_current',
        //   name: 'hif_score_current',
        //   searchable: false,
        //   orderable: true,
        //   render: (data, type, row, meta) => {
        //     return data != null ? parseFloat(data, 10) : 'No data';
        //   },
        // },
        // {
        //   data: 'hif_score_delta',
        //   name: 'hif_score_delta',
        //   searchable: false,
        //   orderable: true,
        //   render: (data, type, row, meta) => {
        //     return data != null ? parseFloat(data, 10).toFixed(0) : '-';
        //   },
        // },
        // {
        //   data: 'registration_id',
        //   name: 'registration_id',
        //   __adminOnly: true,
        //   render: (data, type, row, meta) => {
        //     if (!data) return '-';
        //     const value = data.length <= 10 ? data : `${data.substr(0, 5)}...${data.substr(-5)}`;
        //     return `<a
        //      href="javascript:void(0);"
        //      class="mr-2 show-dataTable-value"
        //      data-value="${data}"
        //    >
        //      ${value}
        //    </a>`;
        //   },
        // },
        // {
        //   data: 'voip_registration_id',
        //   name: 'voip_registration_id',
        //   __adminOnly: true,
        //   render: (data, type, row, meta) => {
        //     if (!data) return '-';
        //     const value = data.length <= 10 ? data : `${data.substr(0, 5)}...${data.substr(-5)}`;
        //     return `<a
        //      href="javascript:void(0);"
        //      class="mr-2 show-dataTable-value"
        //      data-value="${data}"
        //   >
        //     ${value}
        //   </a>`;
        //   },
        // },
        // {
        //   data: 'rapt_score',
        //   name: 'rapt_score',
        //   searchable: false,
        //   orderable: true,
        //   __adminOnly: false,
        //   render: (data, type, row, meta) => {
        //     return data ? parseFloat(data, 10).toFixed(0) : '-';
        //   },
        // },
        {
          data: 'if_inactive_reason__reason_description',
          name: 'if_inactive_reason__reason_description',
          searchable: true,
          orderable: true,
          __adminOnly: false,
          render: data => data || '-',
        },
        {
          data: 'outstanding_issues_or_concerns',
          name: 'outstanding_issues_or_concerns',
          searchable: true,
          orderable: true,
          __adminOnly: false,
          render: data => data || '-',
        },
        // {
        //   data: 'form_status',
        //   name: 'form_status',
        //   searchable: false,
        //   orderable: false,
        //   __adminOnly: false,
        //   render: (data, type, row, meta) => {
        //     return data ? data.replace('_', ' ') : '-';
        //   },
        // },
      ];
      this.dataTable = $('#all-patients').DataTable({
        processing: true,
        serverSide: true,
        lengthMenu: [500, 1000, 2000],
        pageLength: 500,
        paging: true,
        searching: true,
        stateSave: true,
        stateSaveCallback: this.debouncedStateSave,
        stateLoadCallback: this.customStateLoad,
        order: [[1, 'desc']],
        searchDelay: 500,
        cache: false,
        colReorder: {
          fixedColumnsLeft: 2,
        },
        ajax: (data, callback, tableSettings) => {
          data.draw = 0;
          this.$api
            .getClients({
              activeFilter: this.activeFilter,
              columnFilters: this.columnFilters,
              ...data,
            })
            .then((response) => {
              console.log(response)
              const isAnyFilterActive =
                this.selectedHospital > 0 ||
                this.selectedNurse > 0 ||
                this.selectedProvider > 0 ||
                this.selectedCp > 0 ||
                this.filtered.allAlert > 0;

              const filteredRecords = isAnyFilterActive
                ? response.metrics.recordsFiltered
                : response.metrics[this.activeFilter];

              this.draw += 1;
              response.draw = this.draw;

              const transformedData = {
                draw: response.draw,
                recordsTotal: filteredRecords,
                recordsFiltered: filteredRecords,
                data: response.data,
              };

              this.filtered = response.metrics;
              callback(transformedData);
            });
        },

        dom: '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
        buttons: {
          buttons: ['copy', 'excel', 'csv', 'print'],
        },
        columns: allColumns.filter((column) => {
          if (this.isAdmin) return true;
          return !column.__adminOnly;
        }),
      });

      this.unfilteredData = this.dataTable.data().toArray();
      this.columnsIds = allColumns.map(column => column.name);
      this.$nextTick(() => {
        $(this.$refs.table).on('init.dt', () => {
          this.dataTable
            .buttons()
            .container()
            .appendTo(this.$refs.collectionDropdown);
        });
      });
      this.allTableColumns = this.dataTable.context[0].aoColumns.map(
        (aoColumn, index) => {
          let text = $(this.dataTable.column(index).header()).text();
          if (text.includes('Care protocols')) {
            text = 'Care protocols';
          }
          if (text.includes('Monitoring program')) {
            text = 'Monitoring program';
          }
          return {
            text,
            name: aoColumn.name,
          };
        },
      );

      if (this.visibleTableColumns.length === 0) {
        this.visibleTableColumns = this.allTableColumns.slice();

        const lastReadingColumn = this.allTableColumns.find(
          col => col.name === 'last_meeting_datetime' // Change this to the correct key for "Last Reading On"
        );
        const lastActivityColumn = this.allTableColumns.find(
          col => col.name === 'user__last_login_activity' // "Last Activity"
        );

        // Ensure "Last Reading On" is visible and "Last Activity" is hidden
        if (lastReadingColumn) {
          const dtColumn = this.dataTable.column(`${lastReadingColumn.name}:name`);
          dtColumn.visible(true); // Show "Last Reading On"
        }

        if (lastActivityColumn) {
          const dtColumn = this.dataTable.column(`${lastActivityColumn.name}:name`);
          dtColumn.visible(false); // Hide "Last Activity"
        }

        // Save the visibility settings in localStorage
        localStorage.setItem(
          TABLE_COLUMNS_KEY,
          JSON.stringify(this.visibleTableColumns),
        );
      } else {
        // Load and apply the user's saved visibility settings from localStorage
        this.allTableColumns.forEach((columnInfo) => {
          const isVisible = this.visibleTableColumns.some(
            columnVis => columnVis.name === columnInfo.name,
          );
          const dtColumn = this.dataTable.column(`${columnInfo.name}:name`); // Using :name selector
          dtColumn.visible(isVisible);
        });
      }
      this.visibleTableColumns = JSON.parse(
        localStorage.getItem(TABLE_COLUMNS_KEY) || '[]',
      );
      $(this.$refs.table).on('click', '.onepage-report-btn', async (e) => {
        const clientId = $(e.target).closest('a').attr('data-id');
        console.log('onepage', clientId);
        if (!clientId) {
          console.error('No data-id found');
          return;
        }
        that.downloadOnePageReport(clientId);
      });

      $(this.$refs.table).on('click', '.resend-invite-letter', async (e) => {
        const id = $(e.target).closest('a').attr('data-id');
        if (!id) {
          console.error('No data-id found');
          return;
        }

        const data = this.dataTable.data().toArray();
        const row = data.find(entry => entry.id === parseInt(id, 10));
        const ids = data.map(entry => entry.id);
        if (!row) {
          console.error(`No matching row found for id ${id}`);
          return;
        }
        that.$refs.ResendActivationCodeDialog.show(row);
      });

      $(this.$refs.table).on('click', '.OpenProfileInDrawer', (e) => {
        const id = $(e.target).closest('a').attr('data-id');
        this.showPatientProfileInDrawer = true;
        this.$nextTick(() => {
          this.$refs.patientProfileDrawer.show(id);
        });
      });

      // Add a click event listener for the ID cell
      $(this.$refs.table).on('click', '.idToClipboard', (e) => {
        const idElement = $(e.target);
        const id = idElement.text();
        navigator.clipboard
          .writeText(id)
          .then(() => {
            $.notify(`Copied the patient ID ${id} to clipboard`, {
              position: 'top center',
              className: 'success',
            });
          })
          .catch((err) => {
            console.error('Unable to copy to clipboard', err);
          });
      });
      $(this.$refs.table).on('click', '.show-dataTable-value', (e) => {
        const value = $(e.target).closest('a').attr('data-value');
        this.$refs.simpleMessage.show('Message', value);
      });
      $(this.$refs.table).on('click', '.EditProfile', async (e) => {
        const id = $(e.target).closest('a').attr('data-id');
        if (!id) {
          console.error('No data-id found');
          return;
        }
        this.EditProfileInfoTabDrawer = true;
        this.$nextTick(() => {
          this.$refs.EditProfileInfoTabDrawer.show(id);
        });
      });
      $(this.$refs.table).on('click', '.editStatus', async (e) => {
        const id = $(e.target).closest('a').attr('data-id');
        if (!id) {
          console.error('No data-id found');
          return;
        }
        this.EditStatusDrawer = true;
        this.$nextTick(() => {
          this.$refs.EditStatusDrawer.show(id);
        });
      });
      $('.AllPatients-tooltop').tooltip();
    },
    async downloadOnePageReport(clientId) {
      const url =
        `${settings.BACKEND_URL}/api/v-nurse/client/onepage-report` +
        `?id=${clientId}`;

      this.downloadingReport = true;
      this.singleReportDownload = true;
      this.displayReportDownloadingMsgBox = true;
      this.msg = 'Report is being downloaded now...';
      this.$api.downloadFile(url).then((response) => {
        this.downloadingReport = false;
        eventBus.$emit('refreshReportOrdersCounter');
      });
    },
    addedAction(e, dt, node, config) {
      this.allTableColumns.forEach((column, index) => {
        const dtColumn = this.dataTable.column(index);
        const isVisible = dtColumn.visible();

        if (isVisible && !this.visibleTableColumns.includes(column)) {
          this.visibleTableColumns.push(column);
        } else if (!isVisible) {
          const colIndex = this.visibleTableColumns.findIndex(col => col.name === column.name);
          if (colIndex !== -1) {
            this.visibleTableColumns.splice(colIndex, 1);
          }
        }
      });

      this.$refs.toggleColumnsDialog.show();
    },
    async toggleActive(name, event) {
      if (event.target.hasAttribute('data-tooltip')) {
        this.tooltipContent = event.target.getAttribute('data-tooltip');
      }
      this.activeFilter = name;
      console.log(this.activeFilter)
      await this.$nextTick();
      this.dataTable.ajax.reload();
    },
    onChangeInput(data, fieldName) {
      // filter dtatable rows
      let value = data.selectedOption.value;
      if (
        fieldName === 'monitoring_program' &&
        data.selectedOption.value !== 0
      ) {
        value = data.selectedOption.title;
      }
      this.columnFilters[fieldName] = value;
      this.dataTable.ajax.reload();
    },

    reloadDataTable() {
      this.dataTable.ajax.reload();
    },

    handleClick(newTab) {
      this.currentTab = newTab;
    },

    addPatient() {
      this.$router.push({ name: 'AddPatient' }).catch(() => { });
    },

    getStatusBadgeClass(data) {
      return (
        {
          'Low Risk': 'success',
          'Medium Risk': 'warning',
          'High Risk': 'danger',
        }[this.getStatusText(data)] || 'info'
      );
    },

    getStatusText(data) {
      if (data < 6) return 'High Risk';
      if (data > 9) return 'Low Risk';
      return 'Medium Risk';
    },

    toggleColumnVisible(column, visible) {
      const columnIndex = this.allTableColumns.findIndex(col => col.name === column.name);
      const dtColumn = this.dataTable.column(columnIndex);

      if (visible) {
        this.visibleTableColumns.push(column);
        dtColumn.visible(true);
      } else {
        const index = this.visibleTableColumns.findIndex(col => col.name === column.name);
        if (index !== -1) {
          this.visibleTableColumns.splice(index, 1);
        }
        dtColumn.visible(false);
      }

      localStorage.setItem(
        TABLE_COLUMNS_KEY,
        JSON.stringify(this.visibleTableColumns),
      );
    }
    ,
    openColumnVisibilityDrawer() {
      // Sync visibleTableColumns with the actual table column visibility
      this.allTableColumns.forEach((column, index) => {
        const dtColumn = this.dataTable.column(index); // Get the DataTable column by index
        const isVisible = dtColumn.visible(); // Check if the column is visible

        if (isVisible && !this.visibleTableColumns.includes(column)) {
          this.visibleTableColumns.push(column); // Add to visible columns if not already added
        } else if (!isVisible) {
          const colIndex = this.visibleTableColumns.findIndex(col => col.name === column.name);
          if (colIndex !== -1) {
            this.visibleTableColumns.splice(colIndex, 1); // Remove from visible columns if hidden
          }
        }
      });

      this.$refs.toggleColumnsDialog.show(); // Open the column visibility drawer
    },


    stopInterval() {
      if (this.intervalId) clearInterval(this.intervalId);
    },
    getHospitalName(hospitalId) {
      const hospital = this.hospitals.find(
        h => String(h.id) === String(hospitalId),
      );
      return hospital ? hospital.name : '';
    },
    getNurseName(nurseId) {
      const nurse = this.nurses.find(n => String(n.id) === String(nurseId));
      return nurse ? nurse.name : '';
    },
    getProviderName(providerId) {
      const provider = this.surgeons.find(
        s => String(s.id) === String(providerId),
      );
      return provider ? provider.name : '';
    },
    getCareProtocolName(cpId) {
      const protocol = settings.careProtocols.find(
        p => String(p.id) === String(cpId),
      );
      if (protocol) {
        if (protocol.is_global) {
          return protocol.name;
        }
        return `${protocol.name} [${protocol.hospital.name}]`;
      }
      return undefined;
    },
    getProgramName(programValue) {
      const programs = [
        { value: 0, title: 'Monitoring program' },
        { value: 1, title: 'CCM' },
        { value: 2, title: 'PCM' },
        { value: 3, title: 'RPM' },
        { value: 4, title: 'RTM' },
        { value: 5, title: 'Wellness' },
      ];
      const program = programs.find(
        p => String(p.value) === String(programValue),
      );
      return program ? program.title : '';
    },
    resetFilters() {
      this.selectedHospital = 0;
      this.selectedNurse = 0;
      this.selectedProvider = 0;
      this.selectedCp = 0;
      this.selectedProgram = 0;
      this.columnFilters.hospital_id = 0;
      this.columnFilters.assigned_nurse_id = 0;
      this.columnFilters.surgeon_id = 0;
      this.columnFilters.care_protocol_id = 0;
      this.columnFilters.monitoring_program = '';
      this.$nextTick(() => {
        this.reloadDataTable();
      });
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    search() {
      if (this.dataTable) {
        this.dataTable.search(this.textSearch).draw();
      } else {
        console.error('DataTable not initialized!');
      }
    },
    handleInput() {
      if (!this.textSearch) {
        this.clearSearch();
      } else {
        this.search();
      }
    },
    clearSearch() {
      this.textSearch = '';
      if (this.dataTable) {
        this.dataTable.search('').draw();
      }
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
    },
    stopDrag() {
      this.isDragging = false;
    },
    doDrag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 3; // Adjust for sensitivity
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
  },

  async mounted() {
    const el = document.getElementById('lottie-container');
    lottie.loadAnimation({
      container: el,
      animationData,
    });
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu(
        'left-navigation-link>hospital>patients>all-patients',
      );
    });

    this.initializeTable();
    this.$nextTick(() => {
      if (this.dataTable) {
        const state = this.dataTable.state.loaded();
        if (state && state.search) {
          this.textSearch = state.search.search;
        }
      } else {
        console.error('DataTable not initialized!');
      }
    });
    await this.getHospitalList();
    await this.searchSurgeon();
    await this.searchNurse();

    await this.getAllPatientsStats();
    eventBus.$on(
      'CloseEditProfileInfoTab',
      () => {
        this.reloadDataTable();
      },
      this,
    );
    eventBus.$on(
      'CloseEditStatus',
      () => {
        this.reloadDataTable();
      },
      this,
    );
    eventBus.$on(
      'profile-drawer-closed',
      () => {
        this.reloadDataTable();
      },
      this,
    );
    eventBus.$on(
      'setBannerNoticeText',
      () => {
        this.reloadDataTable();
      },
      this,
    );
    eventBus.$on(
      'updatedNurseInfo',
      () => {
        this.getAllPatientsStats();
      },
      this,
    );
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    this.stopInterval();
    eventKeeper.$offOwner(this);
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    SelectorDialogInDrawer,
    ResendActivationCodeDialog,
    SimpleMessage,
    InputField,
    EditProfileInfoTabDrawer,
    PatientProfileInDrawer,
    EditStatusDrawer,
  },
};
</script>
