<template>
  <div class="h-bg-w h-margin-top-l" style="position: relative">
    <div v-if="!loading">
      <div
        class="h1-table h-mobile-padding-top-xl h-bg-w h-padding-top-l"
        :class="{ hidden: clientReportsList.length === 0 }"
      >
        <table id="TableReportsBilling" class="table dataTable TableReportsBilling" ref="table">
          <thead class="thead-light">
            <tr>
              <th style="width: 5%" class="text-nowrap">#</th>
              <th style="width: 5%" class="text-nowrap">Patient Name</th>
              <th style="width: 5%" class="text-nowrap">Patient MRN</th>
              <th style="width: 5%" class="text-nowrap">Patient location</th>
              <th style="width: 5%" class="text-nowrap">Facility name</th>
              <th style="width: 5%" class="text-nowrap">Care provider</th>
              <th style="width: 5%" class="text-nowrap">Facility provider</th>
              <th style="width: 5%" class="text-nowrap">Billing provider</th>
              <th style="width: 5%" class="text-nowrap">Activated at</th>
              <th style="width: 5%" class="text-nowrap">Plan Start Date</th>
              <!-- <th style="width: 5%" class="text-nowrap">End Date</th> -->
              <th style="width: 5%" class="text-nowrap">Days on Service</th>
              <th style="width: 5%" class="text-nowrap">Days with Readings</th>
              <th style="width: 5%" class="text-nowrap">Appointment Minutes</th>
              <th style="width: 5%" class="text-nowrap">At least one call</th>
              <th style="width: 5%" class="text-nowrap">Mental health time</th>
              <th style="width: 5%" class="text-nowrap">Date of service</th>
              <th style="width: 5%" class="text-nowrap">Coding Opinion 1</th>
              <th style="width: 5%" class="text-nowrap">Coding Opinion 2</th>
              <th style="width: 5%" class="text-nowrap">Coding Opinion 3</th>
              <th style="width: 5%" class="text-nowrap">Coding Opinion 4</th>
              <th style="width: 5%" class="text-nowrap">Coding Opinion 5</th>
              <th style="width: 5%" class="text-nowrap">ICD Codes</th>
              <th style="width: 5%" class="text-nowrap">Care Protocol</th>
              <!-- <th>Progress Report</th> -->
            </tr>
          </thead>

          <tbody>
            <tr v-for="clientReport of clientReportsList" :key="clientReport.client.id">
              <td>{{ clientReport.client.id }}</td>
              <td>{{ clientReport.client.name }}</td>
              <td
                :class="{
                  'font-style-italic': !clientReport.client.mrn
                }"
              >
                <a
                  href="javascript:void(0);"
                  @click="openDrawer($event)"
                  :data-id="clientReport.client.id"
                >
                  {{ clientReport.client.mrn === null ? '-' : clientReport.client.mrn }}
                </a>
              </td>
              <td>{{ clientReport.client.location }}</td>
              <td>{{ clientReport.client.hospital }}</td>
              <td>{{ clientReport.client.assigned_nurse }}</td>
              <td>{{ clientReport.client.surgeon_name }}</td>
              <td>{{ clientReport.client.billing_physician}}</td>
              <td>{{ clientReport.client.activated_at }}</td>
              <td>{{ clientReport.client.service_start_date }}</td>
              <!-- <td>{{ clientReport.client.service_end_date }}</td> -->
              <td>{{ clientReport.days_on_service }}</td>
              <td>{{ clientReport.data_count }}</td>
              <td>{{ clientReport.minutes }}</td>
              <td>{{ clientReport.at_least_one_call }}</td>
              <td>{{ clientReport.mental_health_time }}</td>
              <td>{{ clientReport.date_of_service }}</td>
              <td>{{ clientReport.coding_opinion_0 }}</td>
              <td>{{ clientReport.coding_opinion_1 }}</td>
              <td>{{ clientReport.coding_opinion_2 }}</td>
              <td>{{ clientReport.coding_opinion_3 }}</td>
              <td>{{ clientReport.coding_opinion_4 }}</td>
              <td>{{ clientReport.client.icd_codes }}</td>
              <td>{{ clientReport.client.care_protocol }}</td>
              <!-- <td>
                <button
                  :class="{
                    'h-btn': true,
                    'h-btn-safe': getPushTrackerIsEnabled(clientReport),
                    'h-btn-dangerous': !getPushTrackerIsEnabled(clientReport),
                    'h-table-btn': true,
                    'push-to-server': true,
                  }"
                  :disabled="!getPushTrackerIsEnabled(clientReport)"
                  :title="getPushTrackerTitle(clientReport)"
                  @click="pushSftpReport(clientReport)"
                >
                  {{ getPushTrackerText(clientReport) }}
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
        <PatientProfileInDrawer ref="PatientProfileInDrawer" :key="client.id" />
        <SftpPushDialog ref="sftpPushDialog" @reportPushStatusChanged="reportPushStatusChanged" />
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import PatientProfileInDrawer from '../../../all_patients/components/PatientProfileInDrawer';
import SftpPushDialog from '../hospital_report/SftpPushDialog';

export default {
  data() {
    return {
      client: {},
      loading: false,
      clientReportsList: [],
      downloadedReports: {},
      dateStart: null,
      dateEnd: null,
      hospitalId: null,
      isExpended: false,
      showOnlyEligible: true,
    };
  },

  methods: {
    startProcessing() {
      this.loading = true;
    },

    async reload() {
      this.loading = true;
      this.clientReportsList = await this.$api.get(
        `${settings.BACKEND_URL}/api/v-nurse/client/billing-report`,
        {
          'date-start': this.dateStart,
          'date-end': this.dateEnd,
          hospitalId: this.hospitalId,
          onlyEligible: this.showOnlyEligible,
        },
      );
      this.loading = false;
    },

    async load(dateStart, dateEnd, hospitalId, onlyEligible) {
      this.dateStart = dateStart;
      this.dateEnd = dateEnd;
      this.hospitalId = hospitalId;
      this.showOnlyEligible = onlyEligible;
      await this.reload();

      this.dataTable = $(this.$refs.table).DataTable({
        bDestroy: true,
        dom:
          '<"h-row h-space-between h-align-item-center"<"h-show-bt h-margin-left-l header-title"><"h-d-flex h-table-bt"fB>>' +
          '<"h-row"<"h-col-desktop-12 h-col-mobile-12"tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',
        buttons: {
          buttons: [
            {
              extend: 'collection',
              className: 'ExportIcon',
              buttons: ['copy', 'excel', 'csv', 'print'],
            },
          ],
          dom: {
            button: {
              className: 'h-btn',
            },
          },
        },
      });
      $('div.header-title').html(
        "<h3 class='h-h3 h-primary_shade_1'>Billing report<span id='iconExpend' class='ExpendIcon h-margin-left-s'></span></h3>",
      );
      $(document).ready(() => {
        $('#iconExpend').click(() => {
          $('#iconExpend').toggleClass('CollapseIcon');
          $('.TableReportsBilling').toggleClass('Expended');
          $('#headerInput').toggleClass('d-none');
          $('#headerBox').toggleClass('d-none');
        });
      });
    },

    download() {
      const url = `${settings.BACKEND_URL}/api/v-nurse/client/billing-report/download`;
      this.$api.downloadFile(url, {
        hospitalId: this.hospitalId,
        'date-start': this.dateStart,
        'date-end': this.dateEnd,
        onlyEligible: this.showOnlyEligible,
      });
    },

    async openDrawer(event) {
      const id = event.currentTarget.dataset.id;
      await this.$refs.PatientProfileInDrawer.show(id);
    },

    getPushTrackerText(entry) {
      let sftpPushText = 'SFTP push';
      if (entry.report_push_tracker?.is_scheduled_to_push_to_sftp) {
        sftpPushText = 'SFTP scheduled';
      } else if (entry.report_push_tracker?.is_pushed_to_sftp) {
        sftpPushText = 'SFTP pushed';
      }
      return sftpPushText;
    },

    getPushTrackerIsEnabled(entry) {
      let enabled = true;
      if (!entry.hospital__is_push_sftp_reports_enabled || !entry.hospital__is_sftp_setup) {
        enabled = false;
      } else if (!entry.mrn) {
        enabled = false;
      }
      return enabled;
    },

    getPushTrackerTitle(entry) {
      let disabledMessage = '';
      if (!entry.hospital__is_push_sftp_reports_enabled || !entry.hospital__is_sftp_setup) {
        disabledMessage = 'Hospital SFTP not setup or not enabled';
      } else if (!entry.mrn) {
        disabledMessage = 'Client MRN not setup';
      }
      return disabledMessage;
    },

    pushSftpReport(entry) {
      this.$refs.sftpPushDialog.show({
        ...entry,
        reportCode: 'billing',
      });
    },

    reportPushStatusChanged() {
      this.reload();
      this.$emit('reportPushStatusChanged');
    },
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    Loader,
    PatientProfileInDrawer,
    SftpPushDialog,
  },
};
</script>
