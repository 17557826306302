<template>
  <div class="h-bg-gray h-col-desktop-10 h-col-mobaile-12">
    <div v-if="displayReportDownloadingMsgBox" class="MSG-loading">
      <div class="download-container">
        <div class="downloadAnim">
          <lottie-animation
            path="./assets/js/lottie_files/GeneratingReport.json"
          />
        </div>

        <h3 class="h-h3 h-primary_shade_1">{{ defaultDialogBoxTitle }}</h3>
        <h5
          v-if="!singleReportDownload"
          class="h-h5 h-primary_shade_1 h-margin-top-s"
        >
          This may take several minutes. You can keep working and track the
          generating progress from the upper bar of the application
        </h5>
        <h5
          v-if="openDownloadDialogToQueryForStats"
          class="h-h5 h-primary_shade_1 h-margin-top-s"
        >
          <span class="h-accent">159</span> of 488 Reports Processed,
          <span class="h-dangerous">(15 Error)</span>
        </h5>
        <button class="h-btn h-btn-accent h-margin-top-l" @click="msgClose">
          Ok
        </button>
      </div>
    </div>
    <div id="headerBox" class="h-padding-left-l h-bg-w">
      <div class="h-row">
        <div class="h-col-desktop-2 h-col-mobile-12 h-mobile-margin-top-l">
          <label class="h-label">Report period:</label>
          <input
            id="reportDateStart"
            type="month"
            v-model="reportDateStart"
            class="h-input"
          />
        </div>
        <div
          class="h-col-desktop-2 h-col-mobile-12 h-margin-left-l h-mobile-margin-top-l h-mobile-margin-left-0"
        >
          <label class="h-label">Hospital:</label>
          <InputField
            name="hospitalSelect"
            type="select"
            v-model="hospitalId"
            :choices="hospitalChoices"
            :error="errors.hospital_id"
            @input="
              () => {
                errors.hospital_id = null;
              }
            "
          />
        </div>
        <div
          class="h-col h-margin-bottom-s h-col-mobile-12 h-margin-left-l h-mobile-margin-top-l h-mobile-margin-left-0 h-checkbox-flex"
        >
          <InputField
            id="patientReportForm::show-only-eligible"
            type="checkbox"
            checkboxSwitchType="success"
            v-model="showOnlyEligible"
            label="Show Eligible Patients"
            data-title="Only show patients who meet reimbursement criteria"
            class="h-after-tooltip"
          />
        </div>
        <div>
          <button
            class="h-btn h-btn-accent h-margin-top-l h-margin-left-l h-mobile-margin-top-l h-mobile-margin-left-0"
            @click="generateReport"
          >
            Populate report
          </button>
        </div>
        <div
          class="h-2btn-flex h-col h-text-right h-mobile-margin-top-l h-mobile-margin-left-0 h-mobile-text-center"
        >
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/Refresh report.svg"
              class="h-report-icon h-padding-left-s"
              @click="recalculateReport('hospital')"
            />
            <label class="h-margin-top-l"> Recalculate hospital reports </label>
          </div>
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/BHI.svg"
              class="h-report-icon h-padding-left-s"
              @click="recalculateReport('bhi')"
            />
            <label class="h-margin-top-l"> Recalculate BHI reports </label>
          </div>
          <div class="h-icon-tooltip">
            <img
              src="../../../../../../public/assets/images/Down report.svg"
              class="h-report-icon h-padding-left-s"
              @click="downloadAllReports"
              :class="{ 'h-disabled': !hospitalId }"
            />
            <label class="h-margin-top-l">
              Download all reports (zip file)
            </label>
          </div>
          <div class="h-icon-tooltip" v-if="isSuperUser">
            <img
              src="../../../../../../public/assets/images/RepushReport.svg"
              class="h-report-icon h-padding-left-s"
              @click="sendReportsToEMR"
              :class="{ 'h-disabled': !hospitalId }"
            />
            <label class="h-margin-top-l"> Send all to EMR </label>
          </div>
        </div>
      </div>
    </div>
    <TaskStatus
      reportCode="hospital"
      :reportDateStart="this.reportDateStart"
      :hospitalId="this.hospitalId"
    />

    <SelectorDialogInDrawer
      ref="toggleColumnsDialog"
      :allOptions="allTableColumns"
      :selectedOptions="visibleTableColumns"
      :getOptionKey="
        (option) => {
          return option.name;
        }
      "
      :getOptionText="
        (option) => {
          return option.text;
        }
      "
      windowTitle="Toggle table columns"
      listTitle="Table table columns to show"
      @toggleOption="toggleColumnVisible"
    />
    <DownloadAllReportsDrawer ref="downloadAllReportsDrawer" />
    <StartTaskDialog
      ref="startTaskDialog"
      :reportCode="startTaskDialogReportCode"
      :month="getMonth"
      :monthStart="this.reportDateStart"
      :hospitalId="this.hospitalId"
      v-on:startProcessing="startProcessing"
      v-on:endProcessing="endProcessing"
    />
    <PublishReportDialog
      ref="publishReportDialog"
      @downloadRPM="(event) => downloadAllRPMReportsContinue(false, event)"
      @downloadBHI="(event) => downloadAllBHIReportsContinue(false, event)"
    />
    <SendToEMRDialog
      ref="SendToEMRDialog"
      @allReportsPushStatusChanged="allReportsPushStatusChanged"
    />
  </div>
</template>

<style>
div.loading {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #2b5adc;
}
</style>

<script>
import moment from "moment-timezone";
import TaskStatus from "../TaskStatus";
import StartTaskDialog from "../StartTaskDialog";
import settings from "@/settings.js";
import InputField from "@/components/InputField";
import SelectorDialogInDrawer from "@/components/dialogs/SelectorDialogInDrawer";
import eventBus from "../../../../../event-bus";
import DownloadAllReportsDrawer from "@/components/reports/DownloadAllReportsDrawer";
import PublishReportDialog from "./PublishReportDialog";
import SendToEMRDialog from "./SendToEMRDialog.vue";

const TABLE_COLUMNS_KEY = "TABLE_COLUMNS_KEY";

export default {
  data() {
    return {
      errors: {},
      hospitals: [],
      surgeons: [],
      surgeonId: null,
      hospitalId: null,
      reportDateStart: moment().startOf("month").format("YYYY-MM"),
      reportDateEnd: moment().endOf("month").format("YYYY-MM"),
      showOnlyEligible: true,
      msg: "Report is being downloaded now",
      html: '<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>',
      downloadingReport: false,
      downloadingLongNumberOfReports: false,
      displayReportDownloadingMsgBox: false,
      openDownloadDialogToQueryForStats: false,
      datatableProcessingDone: false,
      singleReportDownload: false,
      defaultDialogBoxTitle: "Report is being generated",
      allTableColumns: [],
      visibleTableColumns: [],
      loading: true,
      startTaskDialogReportCode: "hospital",
    };
  },

  computed: {
    hospitalChoices() {
      const choices = this.hospitals.map((hospital) => {
        return [hospital.id, hospital.name];
      });
      choices.sort((a, b) => a[1].localeCompare(b[1]));
      choices.unshift([null, "--- select hospital ---"]);
      return choices;
    },
    getMonth() {
      if (this.reportDateStart) {
        return moment(this.reportDateStart).month() + 1;
      }
      return moment().month() + 1;
    },
    isSuperUser() {
      return settings.isSuperUser();
    },
  },

  methods: {
    startProcessing() {
      this.datatableProcessingDone = false;
      this.$emit("startProcessing");
    },

    endProcessing() {
      this.datatableProcessingDone = true;
      this.$emit("endProcessing");
    },

    async downloadPatientReport(clientId, dateStart, dateEnd) {
      const url =
        `${settings.BACKEND_URL}/api/v-nurse/client/progress-report` +
        `?id=${clientId}&date-start=${dateStart}` +
        `&date-end=${dateEnd}`;
      this.downloadingReport = true;
      this.singleReportDownload = true;
      this.displayReportDownloadingMsgBox = true;
      this.msg = "Report is being downloaded now...";
      this.$api.downloadFile(url).then((response) => {
        this.downloadingReport = false;
        eventBus.$emit("refreshReportOrdersCounter");
      });
    },

    allReportsPushStatusChanged() {
      this.$emit("allReportsPushStatusChanged");
    },

    async sendReportsToEMR() {
      const hospital = this.hospitals.find(
        (hospital) => hospital.id === Number(this.hospitalId)
      );
      const hospitalName = hospital ? hospital.name : "";
      console.log(this.hospitals, this.hospitalId);
      const selectedMonth = $("#reportDateStart").val();
      const dateStart = moment(selectedMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
      const dateEnd = moment(selectedMonth).endOf("month").format("YYYY-MM-DD");
      this.$refs.SendToEMRDialog.show({
        id: null,
        hospital__name: hospitalName,
        hospitalId: this.hospitalId,
        report: {
          date_start: dateStart,
          date_end: dateEnd,
        },
      });
    },

    downloadAllReports() {
      if (!this.hospitalId) return;
      this.$refs.publishReportDialog.show();
    },

    async downloadAllRPMReportsContinue(publish, { skipEmpty }) {
      if (!this.hospitalId) return;
      const selectedMonth = $("#reportDateStart").val();
      const dateStart = moment(selectedMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
      const dateEnd = moment(selectedMonth).endOf("month").format("YYYY-MM-DD");

      const hospitalIdValue = this.hospitalId != null ? this.hospitalId : "";
      const surgeonIdValue = this.surgeonId != null ? this.surgeonId : "";

      const url = `${settings.BACKEND_URL}/api/v-nurse/all-clients/progress-report`;
      const payload = {
        "date-start": dateStart,
        "date-end": dateEnd,
        ext: "zip",
        "skip-empty": skipEmpty,
        "download-in-background": false,
        "show-eligible": this.showOnlyEligible,
        "hospital-id": hospitalIdValue,
        "surgeon-id": surgeonIdValue,
        publish,
      };

      this.defaultDialogBoxTitle =
        "RPM-RTM reports are being generated. A zip file will be in your downloads once it is done.";
      this.displayReportDownloadingMsgBox = true;
      this.msg = "RTM-RPM reports are being downloaded now.";
      this.singleReportDownload = true;

      // Find all buttons with the class 'edit-placeholder'
      // const buttons = document.querySelectorAll('.edit-placeholder');

      // // Process each button
      // buttons.forEach(button => {
      //   let buttonText = button.innerHTML.trim(); // Trim to remove any extra whitespace
      //   if (buttonText === 'RTM-RPM Report') {
      //     button.click();
      //   }
      // });

      if (!this.singleReportDownload) {
        await this.$api.scheduleDownloadOrder(url, payload).then((response) => {
          if (response.status === "error") {
            this.defaultDialogBoxTitle =
              "There is already a report request to process";
          } else eventBus.$emit("refreshReportOrdersCounter");
        });
      } else {
        this.downloadingReport = true;
        try {
          const response = await this.$api.post(url, null, payload);
          console.log("Good job");
          if (response.job_id) {
            await this.pollAllClientsReportsJobStatus(response.job_id);
          } else {
            $.notify("Your reports are scheduled to download", {
              position: "top center",
              className: "success",
            });
          }
        } catch (error) {
          console.error("Error:", error);
          $.notify("There was an error downloading clients reports", {
            position: "top center",
            className: "error",
          });
        }
        // this.$api.downloadFile(url, payload).then((response) => {
        //   eventBus.$emit('refreshReportOrdersCounter');
        // });
      }
    },

    async pollAllClientsReportsJobStatus(jobId) {
      const interval = setInterval(async () => {
        try {
          const url = `${settings.BACKEND_URL}/api/v-nurse/all-clients/progress-report-status`;
          const download_zip_url = `${settings.BACKEND_URL}/api/v-nurse/all-clients/download-report-zip/${jobId}`;
          const statusResponse = await this.$api.get(url, {
            job_id: jobId,
          });
          if (statusResponse.status === "done") {
            clearInterval(interval);
            this.$api.downloadFile(download_zip_url).then((response) => {
              eventBus.$emit("refreshReportOrdersCounter");
            });
            $.notify("Your reports are successfully downloaded", {
              position: "top center",
              className: "success",
            });
          } else if (statusResponse.status === "error") {
            clearInterval(interval);
            $.notify("There was an error downloading the client reports.", {
              position: "top center",
              className: "error",
            });
          }
        } catch (error) {
          console.error("Error:", error);
          clearInterval(interval);
          $.notify("Error fetching job status.", {
            position: "top center",
            className: "error",
          });
        }
      }, 10000); // Poll every 30 seconds
    },

    async downloadAllBHIReportsContinue(publish, { skipEmpty }) {
      if (!this.hospitalId) return;

      const selectedMonth = $("#reportDateStart").val();
      const dateStart = moment(selectedMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
      const dateEnd = moment(selectedMonth).endOf("month").format("YYYY-MM-DD");

      const hospitalIdValue = this.hospitalId != null ? this.hospitalId : "";
      const surgeonIdValue = this.surgeonId != null ? this.surgeonId : "";

      const url = `${settings.BACKEND_URL}/api/v-nurse/all-clients/bhi-report`;
      const payload = {
        "date-start": dateStart,
        "date-end": dateEnd,
        ext: "zip",
        "skip-empty": skipEmpty,
        "download-in-background": false,
        "show-eligible": this.showOnlyEligible,
        "hospital-id": hospitalIdValue,
        "surgeon-id": surgeonIdValue,
        publish,
      };

      this.defaultDialogBoxTitle =
        "BHI reports are being generated. A zip file will be in your downloads once it is done.";
      this.displayReportDownloadingMsgBox = true;
      this.msg = "BHI reports are being downloaded now.";
      this.singleReportDownload = true;

      if (!this.singleReportDownload) {
        await this.$api.scheduleDownloadOrder(url, payload).then((response) => {
          if (response.status === "error") {
            this.defaultDialogBoxTitle =
              "There is already a report request to process";
          } else eventBus.$emit("refreshReportOrdersCounter");
        });
      } else {
        this.downloadingReport = true;
        this.$api.downloadFile(url, payload).then((response) => {
          eventBus.$emit("refreshReportOrdersCounter");
        });
      }
    },

    toggleColumnVisible(column, visible) {
      if (visible) {
        this.visibleTableColumns.push(column);
      } else {
        const index = this.visibleTableColumns.indexOf(column);
        this.visibleTableColumns.splice(index, 1);
      }

      localStorage.setItem(
        TABLE_COLUMNS_KEY,
        JSON.stringify(this.visibleTableColumns)
      );

      const dtColumn = this.dataTable.column(
        this.allTableColumns.indexOf(column)
      );
      dtColumn.visible(!dtColumn.visible());
    },

    msgClose() {
      this.displayReportDownloadingMsgBox = false;
    },
    generateReport() {
      eventBus.$emit("hospitalReportFilterChanged", {
        showOnlyEligible: this.showOnlyEligible,
        hospitalId: this.hospitalId,
        reportDateStart: this.reportDateStart,
      });
    },

    recalculateReport(reportCode) {
      this.startTaskDialogReportCode = reportCode;
      this.$refs.startTaskDialog.show();
    },
  },

  async mounted() {
    const that = this;
    window.test = this;
    this.hospitals = await this.$api.getHospitalsList();
    this.surgeons = await this.$api.searchSeargeon();

    eventBus.$on(
      "downloadFileOperationEnded",
      (event) => {
        this.downloadingReport = false;
        this.displayReportDownloadingMsgBox = false;
      },
      this
    );
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
  },
  components: {
    InputField,
    SelectorDialogInDrawer,
    DownloadAllReportsDrawer,
    TaskStatus,
    StartTaskDialog,
    SendToEMRDialog,
    PublishReportDialog,
  },
};
</script>
