<template>
  <div>
    <div class="h-table-card h-padding-top-l">
      <div class="h-table-search-header h-row h-space-between h-align-item-center">
        <div
          id="filtersHeaderLeft"
          class="h-show-bt h-margin-left-l header-title-left h-alertHeader h-h5">
          <h3 class="h-h3 h-primary_shade_1">
            Activity Logs <span id="CategoryLog" class="filteredLog"> {{ categoryLog }} </span><span class="filteredLog">(<span id="dateLog">{{ dateLog }}</span>)</span>
          </h3>
        </div>
        <div id="filtersHeaderRight" class="h-margin-right-l">
          <SearchPanel @applyFilters="applyFilters" :initialFilters="filters" :client="client" />
        </div>
      </div>

      <div class="h1-table pd56">
        <table id="PatientsActivity" ref="table" class="table w5 dataTable w-100 h-table-log">
          <thead class="thead-light">
            <tr class="activity-header">
              <th style="width: 5%!important" class="h-d-none">ID</th>
              <th style="width: 5%!important" class="text-left">Action</th>
              <th style="width: 5%!important">Date</th>
              <!--<th style="width: 5%">Priority</th>-->
              <th style="width: 25%!important">Categories</th>
              <th style="width: 25%!important">Status</th>
              <th style="width: 35%!important">Description</th>
            </tr>
          </thead>
        </table>

        <PushNotificationDialog ref="pushNotificationDialog" />
        <EditAlertDialog ref="editAlertDialog" @updated="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import PushNotificationDialog from './components/PushNotificationDialog';
import EditAlertDialog from './components/EditAlertDialog';
import SearchPanel from './components/SearchPanel';

export default {
  props: ['client', 'initialFilters'],
  data() {
    const filters = {
      dateStart: moment()
        .subtract(50000, 'days')
        .format(),
      dateEnd: moment().format(),
      alertStatus: 'active',
      category: 'Follow-up Required',
      clientId: this.client.id,
      topic: '',
    };
    return {
      dataTable: null,
      visibleTableLogColumns: [],
      selectedStatus: '',
      selectedCategory: '',
      categoryLog: 'Follow-up Required',
      dateLog: 'All-time',
      clientId: this.client.id,
      filters: this.initialFilters ? this.initialFilters : filters,
    };
  },

  methods: {
    refresh() {
      this.$el.classList.add('disabled');
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = null;
      }
      this.$nextTick(() => {
        this.init();
        this.$el.classList.remove('disabled');
      });
    }
    ,
    updateSubtitle() {
      // Setting category subtitle
      if (this.filters.topic === 'Oximeter readings') {
        this.categoryLog = 'Oximeter readings alerts';
      } else if (this.filters.topic === 'Login') {
        this.categoryLog = this.filters.topic;
      } else if (this.filters.topic === 'steps') {
        this.categoryLog = 'Steps reading';
      } else if (this.filters.topic === 'risk') {
        this.categoryLog = 'Opioid Risk Assessment';
      } else if (this.filters.topic === 'plan end date') {
        this.categoryLog = 'Plan end date';
      } else if (this.filters.category === 'any' && this.filters.alertStatus === '') {
        this.categoryLog = 'All data';
      } else if (this.filters.category === 'any' && this.filters.alertStatus === 'active') {
        this.categoryLog = 'All open';
      } else if (this.filters.category === 'any' && this.filters.alertStatus === 'wont_fix') {
        this.categoryLog = 'All ignore';
      } else if (this.filters.category === 'any' && this.filters.alertStatus === 'resolved') {
        this.categoryLog = 'All resolved';
      } else {
        this.categoryLog = this.filters.category;
      }

      // Setting date subtitle
      const startDate = moment(this.filters.dateStart);
      const sevendays = moment().subtract(7, 'days');
      const thirtydays = moment().subtract(30, 'days');
      const oneyear = moment().subtract(365, 'days');

      if (sevendays.isSameOrBefore(startDate, 'day')) {
        this.dateLog = 'Last 7 days';
      } else if (thirtydays.isSameOrBefore(startDate, 'day')) {
        this.dateLog = 'Last 30 days';
      } else if (oneyear.isSameOrBefore(startDate, 'day')) {
        this.dateLog = 'Last year';
      } else {
        this.dateLog = 'All-time';
      }
    },
    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.$emit('update:initialFilters', this.filters);
      this.refresh();
    },
    changeStatus() {
      this.alertStatus = this.selectedStatus;
      this.refresh();
    },
    init() {
      const that = this;
      const allColumns = [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'action',
          searchable: false,
          orderable: false,
          render: (data, type, row, meta) => {
            return `
              <div class="text-left">
                <a
                  href="javascript:void(0);"
                  class="mr-2 showPushNotificationDialog"
                  data-id="${row[0]}"
                >
                  <i class="fas fa-bell text-primary font-16"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="mr-2 showEditAlertDialog"
                  data-id="${row[0]}"
                >
                  <i class="healenticons-edit text-primary font-16"></i>
                </a>

              </div>
            `;
          },
        },
        {
          name: 'due_date',
          searchable: false,
          render: data => (data ? moment(data).format('MMM D, YYYY h:mm a') : null),
        },
        /*       {
          name: 'Priority',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            const badgeClass =
              {
                High: 'danger',
              }[data] || 'info';
  
            return `<span class="badge badge-soft-${badgeClass}">
               test ${data}
              </span>`;
          },
        }, */
        {
          name: 'status',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            return `${data}`;
          },
        },
        {
          name: 'alert_status',
          searchable: false,
          orderable: true,
          render: (data, type, row, meta) => {
            const value =
              {
                resolved: 'Resolved',
                active: 'Open',
                wont_fix: 'Ignore',
              }[data] || 'None';
            return value;
          },
        },
        {
          name: 'topic',
          render: (data) => {
            if (!data) return null;
            const truncatedText = data.length > 120 ? `${data.substring(0, 120)}...` : data;
            return `<span data-tooltip="${data}">${truncatedText}</span>`;
          },
        },
      ];
      const dataTable = $(this.$refs.table).DataTable({
        order: [[2, 'desc']],
        ordering: true,
        processing: true,
        serverSide: true,
        pageLength: 100,
        ajax: (data, callback, tableSettings) => {
          const payload = JSON.parse(JSON.stringify(data));
          payload.customFilters = that.filters;
          // payload.columns = tableSettings.columns;

          this.$api.getClientsActivity(payload).then((response) => {
            if (!response || !Array.isArray(response.data) || response.data.length === 0) {
              console.warn("Received empty data:", response);
              callback({ data: [] }); // Prevent errors
              return;
            }
            tableSettings.json = response;
            callback(response);
          });
        },

        searching: true,
        dom: '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l"lip>',

        columns: allColumns.filter((column) => {
          if (this.isAdmin) return true;
          return !column.__adminOnly;
        }),
      });
      this.dataTable = dataTable;

      $(this.$refs.table).on('click', '.showPushNotificationDialog', (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        const data = dataTable.data().toArray();
        const row = data.find((entry) => entry[0] === id);
        if (!row) {
          console.warn("Row not found for ID:", id);
          return;
        }

        const headers = $(this.$refs.table)
          .find('th')
          .map((index, el) => $(el).text().trim())
          .toArray();

        headers.unshift('id');
        headers.pop();
        const idParts = id.split('-');
        const resultData = {
          metadata: {
            id: parseInt(idParts[1], 10),
            entryType: idParts[0],
          },
        };
        headers.forEach((header, index) => {
          resultData[header] = row[index] ?? null;
        });
        that.$refs.pushNotificationDialog.show(resultData);
      });

      $(this.$refs.table).on('click', '.showEditAlertDialog', (e) => {
        const id = $(e.target)
          .closest('a')
          .attr('data-id');
        const data = dataTable.data().toArray();
        const row = data.find((entry) => entry[0] === id);
        if (!row) {
          console.warn("Row not found for ID:", id);
          return;
        }

        const headers = $(this.$refs.table)
          .find('th')
          .map((index, el) => $(el).text().trim())
          .toArray();

        headers.unshift('id');
        headers.pop();
        const idParts = id.split('-');
        const resultData = {
          metadata: {
            id: parseInt(idParts[1], 10),
            entryType: idParts[0],
          },
        };
        headers.forEach((header, index) => {
          resultData[header] = row[index];
        });
        that.$refs.editAlertDialog.show(resultData);
      });

      $(this.$refs.table).on('click', '.editAlertStatus', (e) => {
        const dataId = $(e.target)
          .attr('data-id')
          .split('-');
        const payload = {
          alert_type: dataId[0],
          id: dataId[1],
          alert_status: 'resolved',
          resolved: true,
        };
        that.$api.updateClientAlert(payload);
        this.refresh();
      });

      this.updateSubtitle();
    }
  },

  async mounted() {
    this.init()
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    PushNotificationDialog,
    EditAlertDialog,
    SearchPanel,
  },
};
</script>
